import React from 'react';
import ScreensRoot from './screens/Root';
import { BrowserRouter } from 'react-router-dom';

function App() {
    return (
        <BrowserRouter>
             <ScreensRoot />
        </BrowserRouter>
    )
}

export default App
